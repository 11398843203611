<template>
  <div>
    <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="images"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
    <a-skeleton avatar :paragraph="{ rows: 4 }" v-if="loading" />
    <div class="card" v-else>
      <add-actuality @newsAdded="addNews" />

      <div class="p-5">
        <div class="card py-3" v-for="(item, index) in data" :key="index">
          <div class="card-header card-header-flex">
            <div class="d-flex item-center mr-auto">
              <a-avatar v-if="!!logo" size="large" :src="logo" />
              <a-avatar v-else size="large" icon="user" />
              <div
                class="px-3 d-flex flex-column justify-content-center mr-auto"
              >
                <h5 class="mb-0">{{ item.title }}</h5>
                <span>{{ item.createdAt }}</span>
              </div>
            </div>
            <div class="d-flex item-center">
              <a-button
                type="primary"
                @click="() => $router.push('/actuality/' + item._id)"
              >
                <a-icon type="edit" />
                {{ $t("action.consulter") }}
              </a-button>
              <span class="px-1"></span>
              <a-popconfirm
                :title="$t('requis.supp')"
                @confirm="() => supp(item._id)"
              >
                <a-button type="danger">
                  <a-icon type="delete" href="javascript:;" />
                  {{ $t("action.supprimer") }}
                </a-button>
              </a-popconfirm>
            </div>
          </div>
          <div class="card-body" v-html="item.desc"></div>
          <div class="items px-3">
            <!-- Pictures -->
            <div
              v-for="(photo, i) in item.photos"
              :key="i"
              class="item"
              v-if="i < 3"
            >
              <!-- <img class="img-thumbnail" :src="base_url + '/' + photo" alt="" /> -->
              <div class="itemContent pointer" @click="show(item.photos, i)">
                <img :src="base_url + '/' + photo" alt="Image" />
              </div>
            </div>
            <div class="item">
              <div
                v-if="item.photos.length >= 4"
                style="
                  width: 184px;
                  height: 184px;
                  background: #c5c5c5ee;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 30px;
                  color: #686868ee;
                "
                class="itemContent"
              >
                {{ "+" + (item.photos.length - 3).toString() }}
              </div>
            </div>
          </div>

          <!-- Actions -->
          <div class="px-2">
            <!-- unlike -->
            <span key="unlike" class="px-3">
              <a-icon
                v-if="item.liked == false"
                @click="submitLike(item._id, index)"
                class="pointer"
                type="heart"
                style="font-size: 20px"
              />
              <a-icon
                v-else
                type="heart"
                @click="submitUnLike(item._id, index)"
                class="pointer"
                theme="twoTone"
                two-tone-color="#eb2f96"
                style="font-size: 20px"
              />
              <span
                style="padding-left: '8px'; cursor: 'auto'; font-size: 20px"
                class="pointer underline"
                @click="showWhoLiked(item)"
              >
                {{ item.total_likes ? item.total_likes : 0 }}
              </span>
            </span>
            <span key="comment" class="px-3">
              <a-tooltip title="Comment">
                <a-icon
                  type="message"
                  class="pointer"
                  @click="toggleComment(index)"
                  style="font-size: 20px"
                />
              </a-tooltip>
              <span
                style="padding-left: '8px'; cursor: 'auto'; font-size: 20px"
              >
                {{ item.comments ? item.comments.length : 0 }}
              </span>
            </span>
          </div>

          <!-- Comments -->
          <div class="comment-section" v-if="data[index].showComments">
            <span v-for="(comment, i) in item.comments">
              <a-comment class="px-5" v-if="item.comments[i].deleted !== true">
                <template
                  slot="actions"
                  class="padding-0"
                  v-if="comment.type == 'Admin'"
                >
                  <span
                    key="comment-basic-delete"
                    @click="deleteComment(comment._id, index)"
                    >{{ $t("action.supprimer") }}
                  </span>
                </template>
                <a slot="author" v-if="comment.type === 'Admin'">{{
                  schoolName
                }}</a>
                <a slot="author" style="text-transform: capitalize" v-else>{{
                  comment.fullName
                }}</a>
                <a-avatar
                  v-if="comment.type === 'Admin'"
                  slot="avatar"
                  :src="logo"
                  alt="image"
                />
                <a-avatar
                  v-else
                  slot="avatar"
                  :src="base_url + '/' + comment.photo"
                  alt="image"
                />

                <p slot="content">
                  {{ comment.desc }}
                </p>
                <a-tooltip slot="datetime">
                  <span>{{ comment.createdAt }}</span>
                </a-tooltip>
              </a-comment>
            </span>
          </div>

          <!-- new Comment -->
          <div class="pt-3 px-5 row" v-if="data[index].showComments">
            <a-textarea
              class="col-11"
              :placeholder="$t('smartSchedule.comment')"
              auto-size
              v-model="commentRow[index]"
            />
            <!-- {{ commentRow[index] }} -->
            <div class="col-1 d-flex">
              <div class="emoji-selector" v-if="data[index].emojis">
                <ul class="emoji-list">
                  <li
                    v-for="(emoji, y) of emojis"
                    :key="y"
                    @click="setEmoji(emoji, index)"
                  >
                    {{ emoji }}
                  </li>
                </ul>
              </div>
              <a-button
                class="px-2"
                icon="smile"
                @click="toggleEmojis(index)"
              ></a-button>
              <a-button
                class="px-2"
                icon="enter"
                @click="submitComment(commentRow[index], item._id)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal v-model="visibleLikes" :title="$t('actualite.mentionJaimes')">
      <template slot="footer">
        <div></div>
      </template>
      <a-skeleton avatar :paragraph="{ rows: 4 }" v-if="loadingLikes" />
      <div class="comment-section" v-else>
        <div v-for="(like, i) in likes" :key="i" class="d-flex p-2 item-center">
          <!-- image -->
          <div>
            <a-avatar
              v-if="like.type === 'Admin'"
              slot="avatar"
              :src="logo"
              alt="image"
            />
            <a-avatar
              v-else-if="like.type == 'Teacher'"
              slot="avatar"
              :src="base_url + '/' + like.teacher.photo"
              alt="image"
            />
            <a-avatar
              v-else-if="like.type == 'Parent'"
              slot="avatar"
              :src="base_url + '/' + like.parent.photo"
              alt="image"
            />
          </div>
          <!-- full name -->
          <div class="mx-2">
            <span v-if="like.type == 'Admin'">{{ schoolName }}</span>
            <span style="text-transform: capitalize" v-else>{{
              like.fullName
            }}</span>
            <i class="fa fa-briefcase m-2" v-if="like.type == 'Teacher'"></i>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import addActuality from "./components/addActuality.vue";
import { mapState } from "vuex";
var publicationData = [];
export default {
  components: { addActuality },
  name: "publications",
  computed: mapState(["settings"]),
  component: {
    addActuality: addActuality,
  },

  async created() {
    this.getActuality();
    this.logo = this.$store.state.settings.image[0].logo;
    this.base_url = this.$store.state.settings.base_url;
    this.schoolName = this.$store.state.settings.image[0].name;
    fetch("/emojis.json")
      .then((res) => res.json())
      .then((data) => (this.emojis = data));
  },

  data() {
    return {
      loading: true,
      cacheData: null,
      data: null,
      logo: null,
      base_url: null,
      visible: false,
      images: [],
      index: 0,
      likes: [],
      visibleLikes: false,
      emojis: [],
      loadingLikes: true,
    };
  },

  mounted() {},

  methods: {
    addNews(data) {
      data.createdAt = moment(data.createdAt).format("DD/MM/YYYY HH:mm");
      // this.data = [data, ...this.data];
      this.getActuality();
    },

    async getActuality() {
      await apiClient
        .get("/actuality/find")
        .then(({ data }) => {
          let commentsTmp = [];
          let _item = 0;
          this.showEmojis = [];
          publicationData = [...data];
          publicationData = publicationData.map((item) => {
            this.showEmojis[_item++] = false;
            // commentsTmp = { ...commentsTmp, [item._id]: "" };
            commentsTmp = [...commentsTmp, ""];
            return {
              ...item,
              processing: false,
              createdAt: moment(item.createdAt).fromNow(),
              comments: item.comments.map((comment) => ({
                ...comment,
                createdAt: moment(comment.createdAt).fromNow(),
              })),
              showComments: true,
              emojis: false,
            };
          });
          this.commentRow = [...commentsTmp];
          this.data = publicationData;

          this.cacheData = publicationData.map((item) => ({ ...item }));
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("error.aucActualite"));
        });
    },

    supp(key) {
      apiClient
        .delete("/actuality/" + key)
        .then(() => {
          this.$message.success(this.$t("success.pubSupp"));
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item._id !== key);
        })
        .catch(() => this.$message.error(this.$t("success.autorisationSupp")));
    },

    submitLike(actualityId, actualityIndex) {
      this.data[actualityIndex].processing = true;
      console.log("submitLike");
      this.data[actualityIndex].liked = true;
      this.data[actualityIndex].total_likes++;

      const request = {
        type: "Admin",
        actuality: actualityId,
        fullName: this.$store.state.user.userName,
      };
      apiClient
        .post("/actuality/like", { ...request })
        .then(async ({ data }) => {
          //this.getActuality();
        })
        .catch((e) => {
          console.log(e);
          this.data[actualityIndex].liked = false;
          this.data[actualityIndex].total_likes--;
        })
        .finally(() => (this.data[actualityIndex].processing = false));
    },

    async submitUnLike(actualityId, actualityIndex) {
      this.data[actualityIndex].processing = true;
      this.data[actualityIndex].liked = false;
      this.data[actualityIndex].total_likes--;
      let data = {
        type: "Admin",
      };
      await apiClient
        .delete("/actuality/like/remove/" + actualityId, { data })
        .then(async ({ data }) => {
          //await this.getActuality();
        })
        .catch((e) => {
          console.log(e);
          this.data[actualityIndex].liked = true;
          this.data[actualityIndex].total_likes++;
        })
        .finally(() => (this.data[actualityIndex].processing = false));
    },

    async submitComment(comment, actualityId) {
      if (comment == "" || comment == " " || !comment) return;
      const request = {
        desc: comment,
        actuality: actualityId,
        type: "Admin",
        fullName: this.$store.state.user.userName,
      };
      await apiClient
        .post("/actuality/comment", { ...request })
        .then(async ({ data }) => {
          await this.getActuality();
        })
        .catch((e) => console.log(e));
    },

    async deleteComment(commentId, actualityIndex) {
      this.data[actualityIndex].comments = this.data[
        actualityIndex
      ].comments.filter((c) => c._id !== commentId);

      await apiClient
        .delete("/actuality/comment/" + commentId)
        .then(async ({ data }) => {
          await this.getActuality();
        })
        .catch((e) => console.log(e));
    },

    show(images, index) {
      this.images = images.map((img) => this.base_url + "/" + img);
      this.index = index;
      this.visible = true;
    },

    handleHide() {
      this.visible = false;
    },

    toggleComment(actualityIndex) {
      this.data[actualityIndex].showComments =
        !this.data[actualityIndex].showComments;
    },

    async showWhoLiked(actuality) {
      // console.log(actuality.likes);
      this.loadingLikes = true;
      this.visibleLikes = true;
      await apiClient
        .get("/actuality/likes/" + actuality._id)
        .then(({ data }) => {
          // console.log(data);
          this.likes = data.likes;
          this.loadingLikes = false;
        })
        .catch((e) => console.log(e));
    },

    toggleEmojis(actualityIndex) {
      this.data[actualityIndex].emojis = !this.data[actualityIndex].emojis;
    },

    setEmoji(emoji, index) {
      this.commentRow[index] = this.commentRow[index] + emoji;
      this.toggleEmojis(index);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/components/mixins.scss";

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.item-center {
  align-items: center;
}

.items {
  margin-right: rem(-35);
  display: flex;
  flex-wrap: wrap;
}

.item {
  margin-bottom: rem(30);
  margin-right: rem(35);
}

.itemContent {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  width: rem(184);
  height: rem(184);
  margin-bottom: rem(15);

  &:hover {
    .itemControl {
      z-index: 10;
      opacity: 1;
    }
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: rem(184);
  }
}

.pointer {
  cursor: pointer;
}

.comment-section {
  overflow-y: scroll;
  max-height: 200px;
}

.emoji-selector {
  background-color: white;
  border-radius: 1rem;
  cursor: default;
  display: block;
  padding: 10px 0;
  border: 1px solid #eee;
  position: absolute;
  bottom: 110%;
  right: 0%;
  width: 295px;
  height: 350px;
  z-index: 5;
  opacity: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: bottom 0.3s ease, opacity 0.3s ease;
}

.emoji-selector ul.emoji-list {
  height: 340px;
  background-color: white;
  display: flex;
  // flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 1rem 1rem;
  border-radius: 0;
  list-style: none;
}

.emoji-selector ul.emoji-list li {
  height: 35px;
  width: 35px;
  border-radius: 0.25rem;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.emoji-selector ul.emoji-list li:hover {
  background-color: #eeee;
}

.underline {
  text-decoration-line: underline;
  margin: 0 10px;
}
</style>
